import {createRouter,createWebHashHistory} from "vue-router";
// import * as path from "path";
const routes=[
    // { path:"",
    //     name:"App" ,
    //     component: () => import('./App.vue')
    // },

    { path:"/",
        name:"login_page" ,
        component: () => import('@/components/auth/Login') },

    { path:"/register",
        name:"register_page" ,
        component: () => import('@/components/auth/Register')},

    { path:"/forgetPage",
        name:"forgetPage" ,
        component: () => import('@/views/mainpages/ForgetPage')},
    {
        path:'/PresenceAbsencePage/:id/:type',
        name:'PresenceAbsencePage',
        component:()=>import('./views/classes/PresenceAbsencePage')
    },
    {
        path:'/happy_page',
        name:'happy_page',
        component:()=>import('./views/happy')
    },
    {
        path:'/product/:id',
        name:'product_page',
        component:()=>import('./views/shop/product_page')
    }, 
    {
        path:'/shop',
        name:'Shop_page',
        component:()=>import('./views/shop/Shop')
    },
    {
        path:'/SenatorDashboard',
        name:'SenatorDashboard',
        component:()=>import('./views/SenatorDashboard')
    },
    {
        path:'/GradeStudents/:id/:type',
        name:'GradeStudents',
        component:()=>import('./views/classes/GradeStudents')
    },
    {
        path:'/PreviewPage',
        name:'PreviewPage',
        component:()=>import('./views/shop/PreviewPage')
    },
    {
        path:'/CartPage',
        name:'CartPage',
        component:()=>import('./views/mainpages/CartPage')
    },
    {
        path:'/PaymentOrderPage',
        name:'PaymentOrderPage',
        component:()=>import('./views/shop/PaymentOrderPage')
    },
    {
        path:'/DetailsProductsPage/:id',
        name:'DetailsProductsPage',
        component:()=>import('./views/shop/DetailsProductsPage')
    },
    {
        path:'/ShowClasses',
        name:'ShowClasses',
        component:()=>import('./views/classes/ShowClasses')
    },

    { path:"/dashboard",
        name:"dashboard_page" ,
        component: () => import('@/layouts/Dashboard'),
        children:[
            {
            path:'/UserPage',
            name:'UserPage',
            component:()=>import('./views/mainpages/UserPage'),
                children:[
                    {
                        path:'/UserList',
                        name:'UserList',
                        component: () =>import('@/views/userpanel/UserList')
                    },
                    {
                        path:'/InsertUser',
                        name:'InsertUser',
                        component: () =>import('@/views/userpanel/InsertUser')
                    },
                    {
                        path:'/InsertRole',
                        name:'InsertRole',
                        component: () =>import('@/views/userpanel/InsertRole')
                    },
                    {
                        path:'/ManagementUserType',
                        name:'ManagementUserType',
                        component: () =>import('@/views/userpanel/ManagementUserType')
                    }

                ]
            },
            {
                path:'/ManagementPage',
                name:'ManagementPage',
                component:()=>import('./views/mainpages/ManagementPage')
            },
            {
                path:'/TeacherPage',
                name:'TeacherPage',
                component:()=>import('./views/mainpages/TeacherPage')
            },
            {
                path:'/MessagePage',
                name:'MessagePage',
                component:()=>import('./views/message/MessagePAge')
            },
            {
                path:'/UserMessagePAge',
                name:'UserMessagePAge',
                component:()=>import('./views/message/UserMessagePage')
            },
            {
                path:'/StudentsPage',
                name:'StudentsPage',
                component:()=>import('./views/mainpages/StudentsPage')
            },
            {
                path:'/ParentsPage',
                name:'ParentsPage',
                component:()=>import('./views/mainpages/ParentsPage')
            },
            {
                path:'/MyOrderPage',
                name:'MyOrderPage',
                component:()=>import('./views/mainpages/MyOrderPage')
            },
            {
                path:'/classes',
                name:'Class_term_page',
                component:()=>import('./views/classes/Class_term_page'),
                children:[
                    {
                        path:'/ClassesPage',
                        name:'ClassesPage',
                        component:()=>import('./views/classes/ClassesPage'),
                    },
                    {
                        path:'/terms',
                        name:'TermsPage',
                        component:()=>import('./views/classes/TermsPage')
                    },
                ]
            },
            {
                path:'/ShopPage',
                name:'ShopPage',
                component:()=>import('./views/mainpages/ShopPage'),
                children:[
                    {
                        path:'/UnitPage',
                        name:'UnitPage',
                        component: () =>import('@/views/shop/UnitPage')
                    },
                    {
                        path:'/CategoryPage',
                        name:'CategoryPage',
                        component: () =>import('@/views/shop/CategoryPage')
                    },
                    {
                        path:'/TagsPage',
                        name:'TagsPage',
                        component: () =>import('@/views/shop/TagsPage')
                    },
                    {
                        path:'/AttributesPage',
                        name:'AttributesPage',
                        component: () =>import('@/views/shop/AttributesPage')
                    },
                    {
                        path:'/ListProductsPage',
                        name:'ListProductsPage',
                        component: () =>import('@/views/shop/ListProductsPage')
                    },
                    {
                        path:'/AddProductsPage',
                        name:'AddProductsPage',
                        component: () =>import('@/views/shop/AddProductsPage')
                    },

                ]
            },
            {
                path:'/EditProductsPage/:id',
                name:'EditProductsPage',
                component: () =>import('@/views/shop/EditProductsPage')
            },

            {
                path:'/OrderPage',
                name:'OrderPage',
                component:()=>import('./views/orders/OrderPage'),
                children: [
                    {
                        path:'/OrderList',
                        name:'OrderList',
                        component: () =>import('@/views/orders/OrderList')
                    },
                    {
                        path:'/DeliveryMethods',
                        name:'DeliveryMethods',
                        component: () =>import('@/views/orders/DeliveryMethods')
                    },
                    {
                        path:'/SubmitOrder',
                        name:'SubmitOrder',
                        component: () =>import('@/views/orders/SubmitOrder')
                    },
                    {
                        path:'/DeliveryTime',
                        name:'DeliveryTime',
                        component: () =>import('@/views/orders/DeliveryTime')
                    },
                ]
            },
            {
                path:'/WalletPage',
                name:'WalletPage',
                component:()=>import('./views/mainpages/WalletPage')
            },
            {
                path:'/MyClass',
                name:'MyClass',
                component:()=>import('./views/mainpages/MyClass')
            },
            {
                path:'/PopUp_Edit/:type/:id/:name',
                name:'PopUp_Edit',
                component:()=>import('./views/mainpages/PopUp_Edit')
            },
            {
                path:'/ListOfStudentsClass/:id',
                name:'ListOfStudentsClass',
                component:()=>import('./views/mainpages/ListOfStudentsClass')
            },
            {
                path:'/StudentPanel',
                name:'StudentPanel',
                component:()=>import('./views/studentPanel/StudentPanel')
            },
            {
                path:'/TeacherPanel',
                name:'TeacherPanel',
                component:()=>import('./views/teacherPanel/TeacherPanel')
            },
            {
                path:'/ParentsPanel',
                name:'ParentsPanel',
                component:()=>import('./views/parentsPanel/ParentsPanel')
            },
            {
                path:'/Grade/:id',
                name:'GradePage',
                component:()=>import('./views/parentsPanel/Grade')
            },
            {
                path:'/CoursePage',
                name:'CoursePage',
                component:()=>import('./views/mainpages/CoursePage'),
                children:[
                    {
                        path:'/CategoryCourses',
                        name:'CategoryCourses',
                        component: () =>import('@/views/courses/CategoryCourses')
                    },
                    {
                        path:'/CoursesList',
                        name:'CoursesList',
                        component: () =>import('@/views/courses/CoursesList')
                    },
                    {
                        path:'/SessionsOfCourse/:id',
                        name:'SessionsOfCourse',
                        component: () =>import('@/views/courses/SessionsOfCourse')
                    },
                    {
                        path:'/ShowCourses',
                        name:'ShowCourses',
                        component:()=>import('./views/courses/ShowCourses')
                    },
                    {
                        path:'/ShowSessions/:id',
                        name:'ShowSessions',
                        component:()=>import('./views/courses/ShowSessions')
                    },

                ]
            },
            {
                path:'/DiscountPage',
                name:'DiscountPage',
                component:()=>import('./views/mainpages/DiscountPage')
            },
            {
                path:'/ReportPage',
                name:'ReportPage',
                component:()=>import('./views/mainpages/ReportPage')
            },
            {
                path:'/ProfilePage',
                name:'ProfilePage',
                component:()=>import('./views/mainpages/ProfilePage')
            },

        ]}
]



const router=createRouter({
    history:createWebHashHistory(),
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const userInfo = localStorage.getItem('token') ? localStorage.getItem('token') : ''
  
    if (userInfo === '' && (to.path !== '/' && to.path !== '/register' && to.path !== '/shop') ) {
      next({
        path: '/'
      })
    }
    else if (userInfo !== '' && to.path === '/' && from.path === '/') {
      next({ path: '/ManagementPage' })
      localStorage.setItem('last_page','managementTd')
    } 
    else if (userInfo !== '' && from.path !== '/' && to.path === '/') {
      next({ path: from.path })
    } 
    else {
      next()
    }
  })


export default router;
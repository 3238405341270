const { mapState } = require('vuex')
module.exports = {
    computed: mapState({
        count: state => state.count,

        countAlias: 'count',

        countPlusLocalState (state) {
            return state.count + this.localCount
        }
    }),
    // baseUrl: 'http://localhost:8081/',
    // baseUrl: 'https://viana.academy/panel/',
    // baseUrl: 'https://acc.vestatest.ir',
    baseUrl: 'https://viana.vestatest.ir',
    serverBaseUrl: 'https://viana.vestatest.ir'

    // serverBaseUrl: 'https://acc.vestatest.ir'
    // serverBaseUrl: 'https://viana.academy/panel'
  //
  }


   
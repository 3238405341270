import { createApp } from 'vue'

import store from './store/state'
import axios from 'axios'
axios.defaults.baseURL = store.serverBaseUrl + '/api'
axios.defaults.headers.common['Content-Type'] = 'Application/json'
axios.defaults.headers.common.Accept = 'Application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

import VueMultiselect from 'vue-multiselect'



import App from './App.vue'

//router
import router from './router.js'

// SASS
import './assets/sass/index.sass'

import vSelect from 'vue-select'

//chart
import VueApexCharts from 'vue3-apexcharts';

// notif
import Notifications from '@kyvg/vue3-notification'

//datepicker
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker'
// import VueI18n from 'vue-i18n'

// import VueTranslate from 'vue-translate'
// import { translations } from './translations'
// import i18n from './translations'

// const messages = {
//     en: require('./locales/en.json'),
//     fr: require('./locales/fr.json')
//   }
//
//   const i18n = new VueI18n({
//     locale: 'fr',
//     messages
//   })
//
//   window.addEventListener('load', function() {
//     new app({
//       i18n
//     })
//   })

const app= createApp(App)
// app.use(i18n)
app.use (router)
// app.use (I18n)
// app.use(VueI18n)
app.use(Notifications)
app.component('DatePicker', Vue3PersianDatetimePicker)
app.use(VueApexCharts);
app.component('VueMultiselect', VueMultiselect)
app.component('v-select', vSelect)
app.mount('#app')


